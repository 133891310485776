<script>
import NavBarLeft from "@/components/NavBarLeft.vue";
import {pixelsMethods} from "@/state/helpers";
import Cookies from "js-cookie";
export default {
  name: "complete",
  components: {
    NavBarLeft
  },
	methods: {
		...pixelsMethods,
	},
	mounted() {
	  const data = {
		  event: 'purchase',
		  paymentUuid:  Cookies.get('payment')
	  }
		this.sendPurchasePixelEvent(data);
	}
}
</script>

<template>
  <div>
    <div class="layout-wrapper d-lg-flex">
      <NavBarLeft />
      <div class="w-100">
        <div class="m-auto col-md-8 col-lg-6 col-xl-5">
          <div class="card" style="margin-top: 120px;">
            <div class="card-body p-4">
              <div class="text-center px-4">
                <h1>Thank you for buying from us!</h1>
              </div>
              <div class="text-center pt-4">
                <router-link to="/chat" class="btn btn-primary">Go to chats</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>